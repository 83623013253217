<template>
  <secondary-page-layout :title="$t('confirmation')">
    <otp-verify v-model="code" :info="info" @submit="verifyCode" @sendCode="sendConfirmCode" :error-message="errorMessage" :bonus="bonus"></otp-verify>
  </secondary-page-layout>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OtpVerify from '../components/OTP/OtpVerify';
import ePayPayment from "../mixin/ePayPayment";

export default {
  name: "OgpoOtpVerification",
  mixins: [ePayPayment],
  computed: {
    ...mapGetters({
      OTPKEY: 'ogpoExtension/otpKey',
      phoneNumber: 'ogpoExtension/phoneNumber',
      invoiceId: 'ogpoExtension/invoiceId',
      homebankToken: 'ogpoExtension/homebankToken',
      email: 'ogpoExtension/email',
      selectedProductId: "ogpoExtension/selectedProductId",
      products: "ogpoExtension/products",
      clients: "ogpoExtension/clients",
      beginDate: "ogpoExtension/beginDate"
    }),
    info() {
      return { phone_number: this.phoneNumber }
    },
    fixSum() {
      const calculatedSum = this.$store.getters['ogpoExtension/calculatedSum'];

      return calculatedSum?.fixedSum
    },
    bonus() {
      const { selectedProductId, products } = this;

      if(!selectedProductId || products.length === 0) { return }

      return products.find((b) => b.mergeProduct.id === selectedProductId)
    },
  },
  data(){
    return {
      errorMessage: null,
      code: '',
      calculationData: null
    }
  },
  components: {
    OtpVerify
  },
  methods: {
    ...mapActions({
      sendOTP: "ogpoExtension/sendOTP",
      verifyOTP: 'ogpoExtension/verifyOTP',
      saveDraft: 'ogpoExtension/saveWithoutDebounce'
    }),
    async sendConfirmCode() {
      try {
        await this.sendOTP();

        this.showVerify = true;
      } catch (e) {
        throw e
      }
    },
    async verifyCode(code) {
      let loader = this.$loading.show();

      try {
        await this.verifyOTP(code)

        const { data } = await window.axios.post(`insurance-products/calculations/ogpo`, {
          invoiceId: this.invoiceId.toString(),
          amount: this.fixSum,
          holder: this.clients[0],
          phone_number: this.phoneNumber,
          beginDate: this.beginDate,
          calculation: this.$store.state['ogpoExtension'].draftId
        })
        this.saveDraft();
        this.calculationData = data;

        await this.goToPaymentPage(this.calculationData, this.$store.getters['authUser'])



        // await this.ogpoPayment(data, this.invoiceId, this.fixSum, this.$store.getters['authUser'])

        // this.$refs.paymentForm.submit();
      } catch (err) {
        console.log(err)
        if (err.code === 410) {
          this.errorMessage = "Пользователь ввел неверный код подтверждения";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.tryAmount");

        }
        if (err.code === 404) {
          this.errorMessage =  "Время действия кода подтверждения истекло";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.timeGone");

        }
        if (err.code === 400) {
          this.errorMessage = "Пользователь ввел неверный код подтверждения";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.invalidCode");

        }
        if (err.code === 403) {
          this.errorMessage = "Код более не валиден";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.codeGone");
        }
        if (err.code === 500) {
          this.errorMessage = "Не удалось верифицировать номер телефона";
          return;
          this.errorMessage = this.$i18n.t("otpStep.errors.serviceError");
        }
      } finally {
        loader.hide()
      }
    },
  },
};
</script>
